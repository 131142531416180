////////////
  // DOCS //
  ////////////

$mono: 'Space Mono', monospace;
$red: #ff5252;

// CODE STUFFS
pre,
code {
  background-color: $gray-light;
  font-size: 80%;

  &[class*="language-"] {
    color: $black;
    text-shadow: none;
    line-height: 1.7;
  }
}

pre {

  &,
  &[class*="language-"] {
    padding: 25px;
    margin: 10px 20px 25px;
    overflow: auto;
    background-color: $gray-light;
  }

  code {
    border: 0;
    font-weight: 400;
    font-family: $mono;
    padding: 0;
    font-size: 16px;
  }
}

// PRISM
.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: $gray-text;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: $red;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  // color: #66BB6A
  color: darken($red, 10%);
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  font-style: italic;
  color: $gray;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: darken($teal, 10%);
}

code {
  font-family: $mono;
  color: $red;
  padding: 3px 5px;
  font-weight: 600;
  border: 1px solid $gray-border;
  border-radius: 5px;
}

// DOC STUFFS

// LINKS
.sections {
  padding: 20px 0;
  text-align: center;

  h1 {
    color: $red;
    margin-bottom: 10px;
  }

  a {
    font-weight: 600;
    display: inline-block;
    margin: 0 15px 15px 0;
    @extend %type-13;
    background-color: $red;
    color: $white;
    padding: 12px 15px 8px;
    border: 2px solid $red;

    &:hover {
      background-color: $white;
      color: $red;
    }
  }
}

// DOCS
.docs {

  &__section {
    margin: 120px auto;

    &-title {
      margin-bottom: 70px;
      border-bottom: 2px solid $red;
      color: $red;
      background-color: $white;
    }

    &-subtitle {
      margin: 60px auto 40px;
      text-decoration: underline;
      color: $red;

      &-2 {
        margin: 30px auto;
        text-decoration: underline;
        color: $red;
      }
    }
  }

  // Buttons
  &__btn-wrap {
    margin: 10px 0;

    &._gray {
      padding: 10px;
      display: inline-block;
    }
  }

  // Inputs
  &__input-wrap {
    margin: 20px 0;
  }

  // Grid
  &__grid-sample {

    > div {
      margin-top: 20px;
      margin-bottom: 20px;

      > div {
        background-color: $teal;
        padding: 10px;
        color: $white;
        text-align: center;
        font-weight: 600;
      }
    }
  }

  &__toggle-content {
    margin: 20px auto;

    a {
      margin-bottom: 20px;
    }
  }

  p + &__panel {
    margin-top: 30px;
  }
}

.color-ex {
  padding: 20px;
  margin: 0 0 20px;

  p + & {
    margin-top: 20px;
  }
}