// Typography Extends
// ------------------


// HEADERS
// =============================================================================

%header-reset {
  font-size: $base-font-size;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

%type-28 {
  font-size: 28px;
  font-weight: 600;
}

%type-13 {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

%type-17 {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include media($small) {
    font-size: 17px;
  }
}
