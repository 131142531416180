// Variables: Utilities
// --------------------
// These vars apply to utility mixins or @extends

// SPACING VARS
// ============================================================

$space-sm: 10px;
$space-md: 25px;
$space-lg: 45px;
$space-xl: 80px;
